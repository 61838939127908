import styled from "styled-components"

import { Breadcrumbs, IBreadcrumb } from "src/ui/Breadcrumbs/Breadcrumbs"
import { MainView, MainViewProps } from "src/ui/Layout/MainView"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function BreadcrumbView({
  breadcrumbs,
  title,
  description,
  actionBar,
  children,
  metaTitle,
  titleVariant,
}: {
  breadcrumbs: IBreadcrumb[]
  title: React.ReactNode
  description: React.ReactNode
  actionBar?: React.ReactNode
  children: React.ReactNode
  metaTitle?: MainViewProps["metaTitle"]
  titleVariant?: MainViewProps["titleVariant"]
}) {
  return (
    <BreadCrumbBox>
      <Breadcrumbs items={breadcrumbs} />

      <MainView
        title={
          <div>
            <MText variant={titleVariant ?? "heading2"}>{title}</MText>
            <MText color="secondary">{description}</MText>
          </div>
        }
        titleBarProps={{ actionBar }}
        metaTitle={metaTitle}
      >
        {children}
      </MainView>
    </BreadCrumbBox>
  )
}

const BreadCrumbBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
`
